import React from 'react'

export const DateFunctionality = {
    year: new Date().getFullYear(),

}


// export const headerByteCodes = {
//     "2b39312d33332d37393634323837322c0d0a20202020202020202020202020202020202020202b39312d39363734353532303937" : `+91-33-79642872,
//     +91-9674552097`,
//     "696e666f40726561647962656c6c736f6674776172652e636f6d":"contact@readybellsoftware.com",
//     "41482d3132202c53616c746c616b6520536563746f724949202c4b6f6c6b617461202c496e64696120373030303931":"AH-12 ,Saltlake SectorII ,Kolkata ,India 700091",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location",
//     "4c6f636174696f6e":"Location"
// }




