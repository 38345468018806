import React from "react";
import About from "./about";

const ML = () => {
  return (
    <div>
      <h1>This section will be updated soon...</h1>
      <About />
    </div>
  );
};

export default ML;
